@import url(https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css);
@font-face {
  font-family: 'Averta Extrathin';
  src: url(/static/media/Averta-Extrathin.73c31c68.woff2) format('woff2'),
       url(/static/media/Averta-Extrathin.ecc06a07.woff) format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Averta Extrathin';
  src: url(/static/media/Averta-ExtrathinItalic.8de60aa4.woff2) format('woff2'),
       url(/static/media/Averta-ExtrathinItalic.7503ee04.woff) format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-Thin.e681e8d7.woff2) format('woff2'),
       url(/static/media/Averta-Thin.ef2022c8.woff) format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-ThinItalic.8a3c0b15.woff2) format('woff2'),
       url(/static/media/Averta-ThinItalic.c9ff042d.woff) format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-Light.de3463a6.woff2) format('woff2'),
       url(/static/media/Averta-Light.98dea985.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-LightItalic.f5178ada.woff2) format('woff2'),
       url(/static/media/Averta-LightItalic.e36f1056.woff) format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-Regular.3770c98c.woff2) format('woff2'),
       url(/static/media/Averta-Regular.54cd3412.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-RegularItalic.42283dc7.woff2) format('woff2'),
       url(/static/media/Averta-RegularItalic.756c6419.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-Semibold.32d0a844.woff2) format('woff2'),
       url(/static/media/Averta-Semibold.394d2858.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-SemiboldItalic.354d4f6f.woff2) format('woff2'),
       url(/static/media/Averta-SemiboldItalic.c3bb33b4.woff) format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-Bold.985614df.woff2) format('woff2'),
       url(/static/media/Averta-Bold.68a58770.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-BoldItalic.12c90c5e.woff2) format('woff2'),
      url(/static/media/Averta-BoldItalic.c6f4fa21.woff) format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-ExtraBold.95669c79.woff2) format('woff2'),
      url(/static/media/Averta-ExtraBold.cf474897.woff) format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-ExtraBoldItalic.db578e28.woff2) format('woff2'),
       url(/static/media/Averta-ExtraBoldItalic.c9356136.woff) format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-Black.b9b4d224.woff2) format('woff2'),
       url(/static/media/Averta-Black.3c8659b9.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url(/static/media/Averta-BlackItalic.2bbda42b.woff2) format('woff2'),
       url(/static/media/Averta-BlackItalic.ca407516.woff) format('woff');
  font-weight: 900;
  font-style: italic;
}



@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url(/static/media/Inter-Thin.850febbe.woff2) format("woff2"),
       url(/static/media/Inter-Thin.ead42837.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url(/static/media/Inter-ThinItalic.e08d9b2a.woff2) format("woff2"),
       url(/static/media/Inter-ThinItalic.a76db065.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url(/static/media/Inter-ExtraLight.4d9f96f8.woff2) format("woff2"),
       url(/static/media/Inter-ExtraLight.4bd040df.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url(/static/media/Inter-ExtraLightItalic.54d3d9a5.woff2) format("woff2"),
       url(/static/media/Inter-ExtraLightItalic.84c26656.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url(/static/media/Inter-Light.5baca21a.woff2) format("woff2"),
       url(/static/media/Inter-Light.b9920de0.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url(/static/media/Inter-LightItalic.adc70179.woff2) format("woff2"),
       url(/static/media/Inter-LightItalic.0555a46c.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/Inter-Regular.4dd66a11.woff2) format("woff2"),
       url(/static/media/Inter-Regular.7c539936.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/Inter-Italic.9528384c.woff2) format("woff2"),
       url(/static/media/Inter-Italic.e4ad3666.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url(/static/media/Inter-Medium.f6cf0a0b.woff2) format("woff2"),
       url(/static/media/Inter-Medium.7a8cc724.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url(/static/media/Inter-MediumItalic.565a7104.woff2) format("woff2"),
       url(/static/media/Inter-MediumItalic.417907d2.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/Inter-SemiBold.dd8a55ef.woff2) format("woff2"),
       url(/static/media/Inter-SemiBold.1db6c55c.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/Inter-SemiBoldItalic.ac201e30.woff2) format("woff2"),
       url(/static/media/Inter-SemiBoldItalic.81678d1a.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url(/static/media/Inter-Bold.aed27700.woff2) format("woff2"),
       url(/static/media/Inter-Bold.79260e5b.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url(/static/media/Inter-BoldItalic.8ef77a03.woff2) format("woff2"),
       url(/static/media/Inter-BoldItalic.e0879d64.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url(/static/media/Inter-ExtraBold.92d16aee.woff2) format("woff2"),
       url(/static/media/Inter-ExtraBold.38bc51bc.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url(/static/media/Inter-ExtraBoldItalic.57ea76d0.woff2) format("woff2"),
       url(/static/media/Inter-ExtraBoldItalic.0e4b21eb.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url(/static/media/Inter-Black.09f4068b.woff2) format("woff2"),
       url(/static/media/Inter-Black.e3735483.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url(/static/media/Inter-BlackItalic.daa1ca3c.woff2) format("woff2"),
       url(/static/media/Inter-BlackItalic.07e69b53.woff) format("woff");
}



